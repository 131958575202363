<template>
  <v-card elevation="4" class="fill-height">
    <v-card-title class="text-h5 font-weight-medium d-flex align-center justify-space-between">
      Consentimentos Solicitados
      <HelpDialog
        class="mx-4 flex-grow-1"
        title="Modelos de Consentimento"
        message="Preciso de ajuda para encontrar consentimentos solicitados."
      >
        <p>
          Nesta página são mostrados todos os consentimentos que já foram solicitados, assim como seu status.
          Os status possíveis são Aceito, Recusado e Revogado.
        </p>
        <p>
          Ao clicar em um consentimento, você será levado a uma tela com todos os detalhes sobre aquele consentimento.
        </p>
      </HelpDialog>
      <v-btn color="success" :to="{name: 'new-consent'}">
        Nova Solicitação
      </v-btn>
    </v-card-title>
    <v-divider />
    <v-card-subtitle class="pa-1">
      <v-row no-gutters>
        <v-col cols="8">
          <v-text-field
            v-model="searchQuery"
            prepend-inner-icon="mdi-magnify"
            label="Buscar"
            filled
            hide-details="auto"
            class="mr-1"
          />
        </v-col>
        <v-col>
          <div class="d-flex">
            <v-select
              label="Filtrando por"
              v-model="filtering"
              :items="filterItems"
              hide-details="auto"
              filled
              style=""
            />
            <v-tooltip top :open-delay="500">
              <span>
                Recarregar Consentimentos
              </span>
              <template #activator="{ on }">
                <v-btn
                  color="grey lighten-3" depressed
                  class="ml-1 px-5" min-height="57px" min-width="0"
                  v-on="on" @click="loadConsents()"
                >
                  <v-icon>mdi-refresh</v-icon>
                </v-btn>
              </template>
            </v-tooltip>
          </div>
        </v-col>
      </v-row>
    </v-card-subtitle>
    <v-divider />
    <v-data-table
      :headers="headers"
      :items="filteredConsents"
      :items-per-page="-1"
      hide-default-footer
      :loading="loading"
      loading-text="Carregando... Por favor, aguarde"
      no-data-text="Nenhum consentimento encontrado"
      fixed-header
      :height="(contentHeight - 68 - 65 -16 -2) +'px'"
    >
      <template #item="{ item }">
        <tr @click="$router.push( { name: 'consent', params: { consent_id: item.id } } )" style="cursor: pointer">
          
          <td>
            {{ item.bearer ? item.bearer.name : item.new_bearer.name }}
          </td>
          <td>
            {{ item.consent_requests[0].purposes.join("; ") }}
          </td>
          <td>
            {{ $utils.formatDatetime(item.consent_requests[0].creation_datetime) }}
          </td>
          <td align="center">
            <ConsentStatus :answers="item.consent_requests[0].answers" />
          </td>
        </tr>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import HelpDialog from "@/components/HelpDialog.vue";
import ConsentStatus from "@/components/ConsentStatus.vue";

export default {
  name: "Consents",

  props: ['contentHeight'],

  components: { HelpDialog, ConsentStatus, },

  data: () => ({
    loading: false,
    searchQuery: "",
    consents: [],
    headers: [
      { text: "Titular", align: "start", },
      { text: "Finalidades", value: "purpose" },
      { text: "Data de Solicitação", value: "creation_datetime", sortable: false },
      { text: "Situação", value: "status", sortable: false, align: 'center' },
      //{ text: "Forma", value: "form" },
      //{ text: "Controlador", value: "controller" },
    ],
    filtering: -1,
    filterItems: [
      {value: -1, text: "Todos"},
      {value: 'pending', text:"Pendentes"},
      {value: 'accepted', text:"Aceitos"},
      {value: 'refused', text:"Recusados"},
      {value: 'revoked', text:"Revogados"},
    ],
  }),
  
  computed: {
    filteredConsents(){
      let consents = this.consents;
      if (this.filtering != -1){
        if (this.filtering == 'pending'){
          consents = consents.filter((consent) => {
            return consent.consent_requests[0].answers.length == 0;
          });
        } else {
          consents = consents.filter((consent) => {
            return (consent.consent_requests[0].answers[0] || {}).answer == this.filtering;
          });
        }
      }
      const searchQuery = this.searchQuery.toLowerCase();
      return consents.filter((consent) => {
        if (
          (consent.bearer && consent.bearer.name.toLowerCase().includes(searchQuery)) ||
          (consent.new_bearer && consent.new_bearer.name.toLowerCase().includes(searchQuery))
        )
          return true;
        for (let i = 0; i < consent.consent_requests[0].purposes.length; ++i){
          console.log(consent.consent_requests[0].purposes[i].toLowerCase())
          if (consent.consent_requests[0].purposes[i].toLowerCase().includes(searchQuery)){
            return true;
          }
        }
        return false;
      });
    },
  },

  methods: {
    loadConsents(){
      this.loading = true;
      this.$axios
        .get("/consents")
        .then((response) => {
          this.consents = response.data;
        })
        .catch((error) => {
          this.$showMessage(
            "error",
            "Não foi possivel carregar seus consentimentos"
          );
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },

  mounted() {
    this.loadConsents();
  },
};
</script>
