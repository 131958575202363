<template>
  <v-tooltip top :open-delay="500">
    <span> {{ data.status }} </span>
    <template #activator="{ on }">
      <v-icon v-on="on" :color="data.color" v-text="data.icon" />
    </template>
  </v-tooltip>
</template>

<script>
export default {
  name: "ConsentStatus",
  props: {
    answers: {required: true},
  },
  computed: {
    data(){
      if (this.answers.length == 0){
        return {
          status: "Pendente",
          color: "warning",
          icon: "mdi-alert",
        };
      }
      switch (this.answers[0].answer){
        case 'accepted':
          return {
            status: "Aceito",
            color: "success",
            icon: "mdi-shield-check",
          };
        case 'refused':
          return {
            status: "Recusado",
            color: "warning",
            icon: "mdi-cancel",
          };
        case 'revoked':
          return {
            status: "Revogado",
            color: "error",
            icon: "mdi-close-circle",
          };
      }
    },
  },
}
</script>
